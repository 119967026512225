/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from "@core/utils/filter";
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class BankService {
  getBankLists(queryParams) {
    const pars = {
      status: queryParams.status,
    };
    return instance
      .get("/api/Bank/index", { headers: authHeader(), params: pars })
      .then((response) => {
        return response.data.data;
      });
  }
}

export default new BankService();
