/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class EsaService {
  getEsaReportLists(queryParams) {
    const pars = {
      username: queryParams.username,
      userId: queryParams.userId,
      email: queryParams.email,
      name: queryParams.fullname,
      bankAccount: queryParams.bankAccount,
      bankId: queryParams.bankId,
      provinceId: queryParams.provinceId,
      phone: queryParams.phone,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      status: queryParams.status,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/esa/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          esaReports: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  editEseUpdateStatus(bonusGroupData) {
    const FormData = require("form-data");
    const data = new FormData();
    // data.append('name', bonusGroupData.name)
    // data.append('content', bonusGroupData.content)
    data.append("id", bonusGroupData.id);
    data.append("status", bonusGroupData.status);
    // data.append('banner', bonusGroupData.banner)
    return instance.post("/api/esa/update-status", data, {
      headers: authHeader(),
    });
  }
}

export default new EsaService();
