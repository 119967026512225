import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title, paginateArray, sortCompare } from "@core/utils/filter";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  userStatusOptions,
  resolveUserStatus,
  resolveUserEmailVerify,
} from "@core/utils/const/user";

export default function useEsaReportsList() {
  // Use toast
  const toast = useToast();

  const refEsaReportListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    "index",
    { key: "username" },
    { key: "game" },
    { key: "amount" },
    { key: "ratio" },
    { key: "net_amount" },
    { key: "status" },
    { key: "date" },
    { key: "update_by", label: "update " },
    { key: "createdAt", label: "created at" },
    // { key: "action" },
  ];
  const perPage = ref(25);
  const totalEsaReports = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const userIdFilter = ref(null);
  const emailFilter = ref(null);
  const phoneFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const statusFilter = ref(null);

  const refetchData = () => {
    refEsaReportListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      searchQuery,
      usernameFilter,
      phoneFilter,
      emailFilter,
      userIdFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchEsaReports = (ctx, callback) => {
    store
      .dispatch("campaign-stake-amount-report/fetchEsaReports", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        username: usernameFilter.value,
        email: emailFilter.value,
        phone: phoneFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
        userId: userIdFilter.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { esaReports, total, pageSize } = response;
        callback(esaReports);
        totalEsaReports.value = total;
        perPage.value = pageSize;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching esa reports list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refEsaReportListTable.value
      ? refEsaReportListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEsaReports.value,
    };
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserBankAccountStatus = (status) => {
    if (status === 1)
      return { label: "active", icon: "CheckCircleIcon", variant: "success" };
    if (status === 2)
      return { label: "deactive", icon: "XCircleIcon", variant: "danger" };
    if (status === 3)
      return { label: "non-verification", icon: "XIcon", variant: "warning" };
    return { label: "None", icon: "TriangleIcon", variant: "secondary" };
  };

  return {
    fetchEsaReports,
    tableColumns,
    perPage,
    currentPage,
    totalEsaReports,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEsaReportListTable,

    resolveUserBankAccountStatus,
    refetchData,

    // Extra Filters
    usernameFilter,
    userIdFilter,
    emailFilter,
    phoneFilter,
    fromdateFilter,
    statusFilter,
    todateFilter,
    userStatusOptions,
  };
}
