const userStatusOptions = [
	{ label: 'Locked', value: 3 },
	{ label: 'Active', value: 1 },
	{ label: 'Inactive', value: 2 },
]

const resolveUserStatus = stat => {
	if (stat === 3) return { label: 'Locked', variant: 'danger' }
	if (stat === 1) return { label: 'Active', variant: 'success' }
	if (stat === 2) return { label: 'Inactive', variant: 'warning' }
	return { label: 'None', variant: 'secondary' }
}

const resolveUserEmailVerify = verify => {
	if (verify == '0') return {label: 'UnVerified', icon: 'XCircleIcon', variant: 'danger' }
	return {label: 'Verified', icon: 'CheckCircleIcon', variant: 'success' }
}

const kycOptions = [
	{ label: 'Đã KYC', value: 1 },
	{ label: 'Chưa KYC', value: 2 },
	{ label: 'Đang xử lý', value: 3 },
  ]

export {
	userStatusOptions,
	resolveUserStatus,
	resolveUserEmailVerify,
	kycOptions
}
