/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <esa-reports-list-filters
      :username-filter.sync="usernameFilter"
      :user-id-filter.sync="userIdFilter"
      :email-filter.sync="emailFilter"
      :phone-filter.sync="phoneFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :status-filter.sync="statusFilter"
      :status-options="userStatusOptions"
    />
    <b-card no-body class="mb-0">
      <b-table
        ref="refEsaReportListTable"
        class="position-relative table-white-space"
        :items="fetchEsaReports"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{
                name: 'apps-users-view',
                params: { id: data.item.user.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.username }}
            </b-link>
          </b-media>
        </template>

        <template #cell(game)="data">
          {{ data.item.balance_type.name_en }}
        </template>

        <template #cell(amount)="data">
          {{ numberFormat(data.item.amount / 1000) }}
        </template>

        <template #cell(net_amount)="data">
          {{ numberFormat(data.item.net_amount / 1000) }}
        </template>

        <template #cell(date)="data">
          {{ formatRemoveTime(data.item.date) }}
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>
        <template #cell(update_by)="data">
          {{ data.item.updated_by }}
        </template>
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>
        <!-- <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            v-if="data.item.status == 1 && $can('update', 'esastatus')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            @click="showEditReportModal(data.item)"
            class="btn-icon btn-sm mr-50"
          >
            Update
          </b-button>
          <div v-if="data.item.status !== 1 && $can('update', 'esastatus')">
            View
          </div>
        </template> -->
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEsaReports"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <edit-report-modal
      :report-detail.sync="reportDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  formatDateTime,
  formatRemoveTime,
  numberFormat,
} from "@core/utils/filter";
import EsaReportsListFilters from "./EsaReportsListFilters.vue";
import useEsaReportsList from "./useEsaReportsList";
import esaStoreModule from "./esaStoreModule";
import bankStoreModule from "@/views/local-setting/local/bankStoreModule";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    EditReportModal: () => import("./EditReportModal.vue"),
    EsaReportsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BModal,
    // VBModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      roleId: null,
      content: null,
      reportDetail: null,
    };
  },
  methods: {
    showEditReportModal(datas) {
      this.reportDetail = datas;
      this.$bvModal.show("modal-edit-report");
    },
  },
  setup() {
    const CAMPAIGN_ESA_STORE_MODULE_NAME = "campaign-stake-amount-report";

    // Register module
    if (!store.hasModule(CAMPAIGN_ESA_STORE_MODULE_NAME))
      store.registerModule(CAMPAIGN_ESA_STORE_MODULE_NAME, esaStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_ESA_STORE_MODULE_NAME))
        store.unregisterModule(CAMPAIGN_ESA_STORE_MODULE_NAME);
    });
    const resolveStatus = (status) => {
      if (status == 1) return { label: "Pending", variant: "primary" };
      if (status == 2) return { label: "Approved", variant: "success" };
      if (status == 3) return { label: "Reject", variant: "danger" };
    };
    const userStatusOptions = [
      { label: "Pending", value: 1 },
      { label: "Approved", value: 2 },
      { label: "Reject", value: 3 },
    ];
    const {
      fetchEsaReports,
      tableColumns,
      perPage,
      currentPage,
      totalEsaReports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEsaReportListTable,
      refetchData,
      statusFilter,

      // UI
      resolveUserBankAccountStatus,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      phoneFilter,
      fromdateFilter,
      todateFilter,
      // userStatusOptions,
    } = useEsaReportsList();

    return {
      fetchEsaReports,
      tableColumns,
      perPage,
      currentPage,
      totalEsaReports,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEsaReportListTable,
      refetchData,
      resolveStatus,

      // UI
      resolveUserBankAccountStatus,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      phoneFilter,
      fromdateFilter,
      todateFilter,

      formatDateTime,
      numberFormat,
      formatRemoveTime,
      statusFilter,
      userStatusOptions,
    };
  },
};
</script>
