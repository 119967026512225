import EsaService from '@/libs/esa.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchEsaReports(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				EsaService.getEsaReportLists(queryParams)
					.then(response => {
						resolve(response)
					})
					.catch(error => reject(error))
			})
		},
		editEseUpdateStatus(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				EsaService.editEseUpdateStatus(queryParams)
					.then(response => {
						resolve(response)
					})
					.catch(error => reject(error))
			})
		},
    }
}
